<template>
  <v-sheet class="mx-md-5 mx-2 mt-4">
    <v-card :loading="isAppLoading">
      <v-card-text class="d-flex py-6">
        <v-text-field
          class="mr-md-2 mb-6 mb-md-0"
          v-model="parameters.startDate"
          :label="$vuetify.lang.t('$vuetify.base.startDate')"
          type="date"
          clearable
          dense
          hide-details
          :rules="[rules.required]"
        />
        <v-text-field
          class="ml-0 ml-lg-4"
          v-model="parameters.endDate"
          :label="$vuetify.lang.t('$vuetify.base.endDate')"
          type="date"
          clearable
          dense
          hide-details
          :rules="[rules.dateRange(parameters.startDate, parameters.endDate)]"
        />
      </v-card-text>
      <v-card-actions class="justify-center my-6">
        <v-btn @click="search">{{ $vuetify.lang.t("$vuetify.base.submit") }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-divider />

    <process-events
      class="my-10"
      v-if="events && events.length > 0"
      :eventList="events"
      :date="date"
      :domain="domain"
      v-model="processData"
      :key="ts"
    />
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { rules } from "@/store/util/rules.js";
const ProcessEvents = () => import("@/components/inventory/ProcessEvents.vue");
import { parseDomain } from "parse-domain";

export default {
  data: () => ({
    parameters: {
      startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    events: [],
    ts: Date.now(),
    rules,
        domain: null,

  }),
  computed: {
    ...mapGetters("inventoryEvents", ["eventsService", "eventsDateRange"]),
    ...mapGetters("navigation", ["isAppLoading"]),
  },
  components: { ProcessEvents },
  async created() {
    const parseResult = parseDomain(window.location.hostname);
    const { domain } = parseResult;
    this.domain = domain;
    await this.loadProcessTypes();
    this.search();
  },
  methods: {
    ...mapActions("inventoryEvents", ["loadInventoryEvents"]),
    ...mapActions("processes", ["loadProcessTypes"]),
    async search() {
      this.events = await this.loadInventoryEvents({
        startDate: new Date(this.parameters.startDate),
        endDate: new Date(this.parameters.endDate),
        types: ["inputs", "outputs"],
        processTypes: null,
      });
      this.ts = Date.now();
    },
  },
};
</script>

<style>
</style>